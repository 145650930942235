@import url("./assets/fonts/stylesheet.css");
@tailwind base;
@tailwind components;
@tailwind utilities;

::-webkit-scrollbar {
  height: 12px;
  width: 12px;
}

::-webkit-scrollbar-track {
  background: #eeeeee;
  border-radius: 8px;
}

::-webkit-scrollbar-thumb {
  border-radius: 8px;
  background: #b0b0b0;
}
