@font-face {
  font-family: GothamLight;
  src: url("./GothamRounded-Light.otf");
}

@font-face {
  font-family: GothamMedium;
  src: url("./GothamRounded-Medium.otf");
}

@font-face {
  font-family: GothamBold;
  src: url("./GothamRounded-Bold.otf");
}

@font-face {
  font-family: GothamBook;
  src: url("./GothamRounded-Book.otf");
}
